<template>
    <div id="dashboard-home-page-section">
        <div class="bg-primary px-2 px-sm-5 top-header">
            <!--            <router-link :to="{name:'adminDashboard'}" class="text-light h3">Pain Cure Dental Care</router-link>
                        <ul class="nav navbar">
                            <li class="nav-item">
                                <router-link :to="{name:'adminDashboard'}" class="nav-link text-light">Dashboard</router-link>
                            </li>
                            <li v-if="$root.$can('staff', 'staff-view')" class="nav-item">
                                <router-link :to="{name:'staffList'}" class="nav-link text-light">Staff List</router-link>
                            </li>
                            <li v-if="$root.$can('patient', 'patient-view')" class="nav-item">
                                <router-link :to="{name:'patientList'}" class="nav-link text-light">Patient List</router-link>
                            </li>
                            <li v-if="$root.$can('medicine', 'medicine-view')" class="nav-item">
                                <router-link :to="{name:'medicines'}" class="nav-link text-light">Medicine</router-link>
                            </li>
                            <li v-if="$root.$can('service', 'service-view')" class="nav-item">
                                <router-link :to="{name:'services'}" class="nav-link text-light">Services</router-link>
                            </li>
                            <li v-if="$root.$can('category', 'category-view')" class="nav-item">
                                <router-link :to="{name:'categories'}" class="nav-link text-light">Categories</router-link>
                            </li>
                        </ul>
                        <a href="/" target="_blank" class="logo rounded-circle overflow-hidden">
                            <img class="h-100 w-100 fit-cover" src="@/assets/logo.png" alt="logo">
                        </a>-->

            <nav class="navbar navbar-expand-lg navbar-dark h-100">
                <div class="container-fluid">
                    <router-link :to="{name:'adminDashboard'}" class="navbar-brand text-light h3">Pain Cure Dental
                        Care
                    </router-link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav ms-auto me-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <router-link :to="{name:'adminDashboard'}" class="nav-link text-light">Dashboard
                                </router-link>
                            </li>
                            <li v-if="$root.$can('staff', 'staff-view')" class="nav-item">
                                <router-link :to="{name:'staffList'}" class="nav-link text-light">Staff List
                                </router-link>
                            </li>
                            <li v-if="$root.$can('patient', 'patient-view')" class="nav-item">
                                <router-link :to="{name:'patientList'}" class="nav-link text-light">Patient List
                                </router-link>
                            </li>
                            <li v-if="$root.$can('medicine', 'medicine-view')" class="nav-item">
                                <router-link :to="{name:'medicines'}" class="nav-link text-light">Medicine</router-link>
                            </li>
                            <li v-if="$root.$can('service', 'service-view')" class="nav-item">
                                <router-link :to="{name:'services'}" class="nav-link text-light">Services</router-link>
                            </li>
                            <li v-if="$root.$can('category', 'category-view')" class="nav-item">
                                <router-link :to="{name:'categories'}" class="nav-link text-light">Categories
                                </router-link>
                            </li>
                        </ul>
                        <a href="/" target="_blank" class="logo rounded-circle overflow-hidden d-none d-sm-block">
                            <img class="h-100 w-100 fit-cover" src="@/assets/logo.png" alt="logo">
                        </a>
                    </div>
                </div>
            </nav>


        </div>

    </div>
</template>

<script>

export default {
    data() {
        return {}
    },
}
</script>
