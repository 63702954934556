<template>
  <div class="table-actions">
    <a @click.prevent="print()" href="#" class="waves-effect btn-flat nopadding">
      <i class="material-icons">print</i>
    </a>
    <a  href="#" class="waves-effect btn-flat nopadding">
      <i class="material-icons">description</i></a>
    <a  @click.prevent="search()" href="#" class="waves-effect btn-flat nopadding">
      <i  class="material-icons">search</i>
    </a>
  </div>

</template>

<script>
  export default {
    props: ['pagination', 'offset'],
    methods: {
      search() {
        this.$emit('search');
      },
      print() {
        this.$emit('print');
      },

    },
    computed: {
    }
  }
</script>
<style scoped>
  @media print {
    @page {
      size: landscape;
    }
    .table-actions{
      display: none !important;
    }
    body * {
      visibility: hidden;
    }
  }
  /*body * {*/
  /*  visibility: hidden;*/
  /*}*/
  /*#section-to-print, #section-to-print * {*/
  /*  visibility: visible;*/
  /*}*/
  /*#section-to-print {*/
  /*  position: absolute;*/
  /*  left: 0;*/
  /*  top: 0;*/
  /*}*/
</style >
