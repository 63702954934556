<template>
    <div id="why-choose-section" class="sec-p-70">
        <div class="container-fluid">
            <div class="row">
                <div class="col-10 offset-1">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <div class="common-title-section common-title-section2">
                                <h1>Why Choose Us?</h1>
                                <span class="decor"><span class="inner"></span></span>
                            </div>
                            <div class="accordion mb-5 mb-sm-0" id="accordionExample">
                                <div class="accordion-item border-0 position-relative ps-4">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="true"
                                                aria-controls="collapseOne">
                                            <b>We are always carefull to our patient and service</b>
                                        </button>
                                    </h2>
                                    <span class="decor"><span class="inner"></span></span>

                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                         aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Our Patient's comfort is our first priority. We don't want to disappoint
                                            them for a single visit. Even, we can feel their pain when they come to us
                                            to have a quick solution.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item border-0 position-relative ps-4">
                                    <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                aria-expanded="false" aria-controls="collapseTwo">
                                            <b>Book and appoinment with any specialist anytime</b>
                                        </button>
                                    </h2>
                                    <span class="decor"><span class="inner"></span></span>

                                    <div id="collapseTwo" class="accordion-collapse collapse"
                                         aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            We are open day long to serve our patients. You can book your slot with our
                                            experienced consultants at your comfortable time. It's just a matter of a
                                            quick call to reach us.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item border-0 position-relative ps-4">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                                aria-expanded="false" aria-controls="collapseThree">
                                            <b>We offer lot of service in a best price</b>
                                        </button>
                                    </h2>
                                    <span class="decor"><span class="inner"></span></span>

                                    <div id="collapseThree" class="accordion-collapse collapse"
                                         aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            There is a myth "Dental treatment is expensive"! You can have a very good
                                            experience with our lower prices and amazing offers. You can save your extra
                                            expenses with the proper treatment.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="d-flex">
                                <div class="img-masonary">
                                    <img class="h-100 img-fluid" src="@/assets/doctor/h-1.jpg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "WhyChooseUs"
}
</script>

<style scoped>

</style>
