import Vue            from 'vue'
import App            from './App.vue'
import router         from './router'
import store          from './store'
import './scss/main.scss'
import 'bootstrap'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import './globalComponents'
import './user-activity-check'
//Print page
import VueHtmlToPaper from 'vue-html-to-paper';

const options = {
    name  : '_blank',
    specs : [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: [
        'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css',
        'https://unpkg.com/kidlat-css/css/kidlat.css',
    ]
};
Vue.use(VueHtmlToPaper, options);
// Filters
import './filters/filters.js'
//sweetalert2
window.Swal  = require('sweetalert2');
//Toast
const Toast  = Swal.mixin({
    toast            : true,
    position         : 'top-end',
    showConfirmButton: false,
    timer            : 3000,
    timerProgressBar : true,
    didOpen          : (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});
window.Toast = Toast;


//services
import ApiService      from '@/services/api.service.js'
import * as JwtService from "@/services/jwt.service.js";

ApiService.init();

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requireAuth)) {
        if (!JwtService.getToken()) {
            next({
                name  : 'login',
                params: {nextUrl: to.fullPath}
            })
        } else {
            ApiService.get('/user').then(response => {
                store.commit("auth/GETUSER", response.data.data);
                store.commit("auth/GETPERMISSIONS", response.data.data.permissions);
                /* store.commit("auth/AUTHROLE", response.data.data.left_menu_component);*/
                next()
            }).catch(error => {
                JwtService.destroyToken();
                next({name: 'login'})
            })
            // TODO : need to check the token on each route change
            // next();
        }
    }

    if (to.name == 'login') {
        if (JwtService.getToken()) {
            next({name: 'adminDashboard'});
        }
    }

    next();
    Vue.nextTick(() => {
        document.title = `${to.meta.title} - ${process.env.VUE_APP_TITLE}` || process.env.VUE_APP_TITLE
    })
});


new Vue({
    router,
    store,
    methods : {
        $can(group_name, permission_name) {
            if (this.user.is_admin === 0) {
                let group_data = this.permissions[group_name];
                return group_data?.includes(permission_name)
            } else {
                return true
            }
        },
    },
    computed: {
        user() {
            return this.$store.state.auth.user;
        },
        permissions() {
            return this.$store.state.auth.permissions;
        },
        /*auth_role() {
            return this.$store.state.auth.auth_role;
        },*/
    },
    render  : h => h(App)
}).$mount('#app');

