<template>
    <div class="position-relative" style="min-height: 100vh">
        <top-bar></top-bar>
        <router-view class="pb-5" ref="admin-layout"/>
        <div class="position-absolute w-100 bottom-0 py-3 text-center text-light">

            <div class="d-flex justify-content-center align-items-center ">
                <div>
                    <a class="fw-bold text-primary fs-12" target="_blank" href="https://www.softwareart.xyz/">
                        Powered by  <img class="mx-2" style="width: 65px" src="@/assets/softart.png" alt="">
                        <small>Version: 202201.01.00</small>
                    </a>

                </div>


            </div>
        </div>
    </div>
</template>

<script>
import TopBar from "@/views/admin/layouts/TopBar";

export default {
    name      : "Layout",
    components: {TopBar}
}
</script>
