<template>
    <div id="doctors-section" class="sec-p-70 pt-0">
        <div class="container-fluid">
            <div class="row">
                <div class="col-10 offset-1">
                    <div class="common-title-section text-left">
                        <h1>Meet Our Doctors</h1>
                        <span class="decor"><span class="inner"></span></span>
                    </div>

                    <div class="row">
                        <carousel v-if="$parent.doctors.length" :margin="30" :dots="false" :autoplay="true" :autoplayHoverPause="true"
                                  :loop="true" :nav="true"
                                  :responsive="{0:{items:1,nav:false,margin:0},600:{items:3,nav:true}}"
                                  class="position-relative doctors-slider">
                            <div class="item mb-3" v-for="(item, index) in $parent.doctors">
                                <div class="img-box">
                                    <img class="h-100 mx-auto" :src="item.image" alt="">
                                    <a href="#" class="position-absolute d-none">+</a>
                                </div>
                                <div class="content text-center">
                                    <h3 class="mb-2 mt-3">{{ item.name }}</h3>
                                    <p class="fs-12 text-primary">{{ item.doctor_info }}</p>
                                </div>
                            </div>
                        </carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
    components: {carousel},
    name      : "Doctors"
}
</script>
