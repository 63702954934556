<template>
 <div id="we-specialize" class="sec-p-70 pb-0">
     <div class="container-fluid">
         <div class="row">
             <div class="col-10 offset-1">
                 <div class="common-title-section text-center">
                     <h1>We Specialize In</h1>
                     <span class="decor"><span class="inner"></span></span>
                 </div>
                 <div class="row">
                     <carousel :responsive="{0:{items:1,nav:false},600:{items:4,nav:false}}" :margin="30" :dots="true" :autoplay="true" :loop="true" :nav="false" :autoplayHoverPause="true" class="position-relative doctors-slider">
                         <div class="mb-4">
                             <div class="specialize-content rounded overflow-hidden">
                                 <div class="image-box">
                                     <img class="w-100" src="@/assets/specialize/OrthodonticsTreatment.jpeg" alt="">
                                 </div>
                                 <div class="bg-gray px-3 text-center d-flex align-items-center justify-content-center">
                                     <h4>Orthodontics Treatment</h4>
                                 </div>
                             </div>
                         </div>
                         <div class="mb-4">
                             <div class="specialize-content rounded overflow-hidden">
                                 <div class="image-box">
                                     <img class="w-100" src="@/assets/specialize/FlexibleDenture.jpeg" alt="">
                                 </div>
                                 <div class="bg-gray px-3 text-center d-flex align-items-center justify-content-center">
                                     <h4>Flexible Denture</h4>
                                 </div>
                             </div>
                         </div>
                         <div class="mb-4">
                             <div class="specialize-content rounded overflow-hidden">
                                 <div class="image-box">
                                     <img class="w-100" src="@/assets/specialize/Impactedtoothextraction.jpeg" alt="">
                                 </div>
                                 <div class="bg-gray px-3 text-center d-flex align-items-center justify-content-center">
                                     <h4>Impacted tooth extraction</h4>
                                 </div>
                             </div>
                         </div>
                         <div class="mb-4">
                             <div class="specialize-content rounded overflow-hidden">
                                 <div class="image-box">
                                     <img class="w-100" src="@/assets/specialize/Implantbridge.jpeg" alt="">
                                 </div>
                                 <div class="bg-gray px-3 text-center d-flex align-items-center justify-content-center">
                                     <h4>Implant bridge</h4>
                                 </div>
                             </div>
                         </div>
                         <div class="mb-4">
                             <div class="specialize-content rounded overflow-hidden">
                                 <div class="image-box">
                                     <img class="w-100" src="@/assets/specialize/Implantcap.jpeg" alt="">
                                 </div>
                                 <div class="bg-gray px-3 text-center d-flex align-items-center justify-content-center">
                                     <h4>Implant cap</h4>
                                 </div>
                             </div>
                         </div>
                         <div class="mb-4">
                             <div class="specialize-content rounded overflow-hidden">
                                 <div class="image-box">
                                     <img class="w-100" src="@/assets/specialize/polish.jpg" alt="">
                                 </div>
                                 <div class="bg-gray px-3 text-center d-flex align-items-center justify-content-center">
                                     <h4>Polish</h4>
                                 </div>
                             </div>
                         </div>
                         <div class="mb-4">
                             <div class="specialize-content rounded overflow-hidden">
                                 <div class="image-box">
                                     <img class="w-100" src="@/assets/specialize/root_canal.jpg" alt="">
                                 </div>
                                 <div class="bg-gray px-3 text-center d-flex align-items-center justify-content-center">
                                     <h4>Root canal</h4>
                                 </div>
                             </div>
                         </div>
                         <div class="mb-4">
                             <div class="specialize-content rounded overflow-hidden">
                                 <div class="image-box">
                                     <img class="w-100" src="@/assets/specialize/Scalingultrasonic.jpg" alt="">
                                 </div>
                                 <div class="bg-gray px-3 text-center d-flex align-items-center justify-content-center">
                                     <h4>Scaling ultrasonic</h4>
                                 </div>
                             </div>
                         </div>
                         <div class="mb-4">
                         <div class="specialize-content rounded overflow-hidden">
                             <div class="image-box">
                                 <img class="w-100" src="@/assets/specialize/tooth-whiteninglaser.jpg" alt="">
                             </div>
                             <div class="bg-gray px-3 text-center d-flex align-items-center justify-content-center">
                                 <h4>Tooth-whitening laser</h4>
                             </div>
                         </div>
                     </div>
                     </carousel>
                 </div>
             </div>
         </div>
     </div>
 </div>
</template>

<script>
import carousel from 'vue-owl-carousel'
export default {
    components: {carousel},
    name: "WeSpecialize"
}
</script>

<style scoped>

</style>
