<template>
    <div id="our-department-section" class="sec-p-70">
        <div class="container-fluid">
            <div class="row">
                <div class="col-10 offset-1">
                    <div class="common-title-section text-center">
                        <h1>Our Department</h1>
                        <p>Lorem ipsum is a dummy text it will use for subtitle here</p>
                        <span class="decor"><span class="inner"></span></span>
                    </div>
                    <div class="row">
                        <ul class="justify-content-center mb-5 nav nav-pills" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Normal Checkup</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Oral Surgery</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Dental Filling</button>
                            </li>
                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="specialize-content">
                                            <div class="image-box">
                                                <img class="w-100" src="@/assets/featured-1.jpg" alt="">
                                            </div>
                                            <div class="bg-gray pb-4 px-4 text-center">
                                                <h4 class="py-3">Consisting</h4>
                                                <p class="text-secondary fs-14">There are many variations of lorem passagei of Lorem Ipsum available but the majority have</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="specialize-content">
                                            <div class="image-box">
                                                <img class="w-100" src="@/assets/featured-2.jpg" alt="">
                                            </div>
                                            <div class="bg-gray pb-4 px-4 text-center">
                                                <h4 class="py-3">Prototype</h4>
                                                <p class="text-secondary fs-14">There are many variations of lorem passagei of Lorem Ipsum available but the majority have</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="specialize-content">
                                            <div class="image-box">
                                                <img class="w-100" src="@/assets/featured-3.jpg" alt="">
                                            </div>
                                            <div class="bg-gray pb-4 px-4 text-center">
                                                <h4 class="py-3">Implementing</h4>
                                                <p class="text-secondary fs-14">There are many variations of lorem passagei of Lorem Ipsum available but the majority have</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="specialize-content">
                                            <div class="image-box">
                                                <img class="w-100" src="@/assets/featured-4.jpg" alt="">
                                            </div>
                                            <div class="bg-gray pb-4 px-4 text-center">
                                                <h4 class="py-3">Happy Smiling</h4>
                                                <p class="text-secondary fs-14">There are many variations of lorem passagei of Lorem Ipsum available but the majority have</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="specialize-content">
                                            <div class="image-box">
                                                <img class="w-100" src="@/assets/featured-1.jpg" alt="">
                                            </div>
                                            <div class="bg-gray pb-4 px-4 text-center">
                                                <h4 class="py-3">Consisting</h4>
                                                <p class="text-secondary fs-14">There are many variations of lorem passagei of Lorem Ipsum available but the majority have</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="specialize-content">
                                            <div class="image-box">
                                                <img class="w-100" src="@/assets/featured-2.jpg" alt="">
                                            </div>
                                            <div class="bg-gray pb-4 px-4 text-center">
                                                <h4 class="py-3">Prototype</h4>
                                                <p class="text-secondary fs-14">There are many variations of lorem passagei of Lorem Ipsum available but the majority have</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="specialize-content">
                                            <div class="image-box">
                                                <img class="w-100" src="@/assets/featured-3.jpg" alt="">
                                            </div>
                                            <div class="bg-gray pb-4 px-4 text-center">
                                                <h4 class="py-3">Implementing</h4>
                                                <p class="text-secondary fs-14">There are many variations of lorem passagei of Lorem Ipsum available but the majority have</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="specialize-content">
                                            <div class="image-box">
                                                <img class="w-100" src="@/assets/featured-1.jpg" alt="">
                                            </div>
                                            <div class="bg-gray pb-4 px-4 text-center">
                                                <h4 class="py-3">Consisting</h4>
                                                <p class="text-secondary fs-14">There are many variations of lorem passagei of Lorem Ipsum available but the majority have</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="specialize-content">
                                            <div class="image-box">
                                                <img class="w-100" src="@/assets/featured-2.jpg" alt="">
                                            </div>
                                            <div class="bg-gray pb-4 px-4 text-center">
                                                <h4 class="py-3">Prototype</h4>
                                                <p class="text-secondary fs-14">There are many variations of lorem passagei of Lorem Ipsum available but the majority have</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OurDepartment"
}
</script>

<style scoped>

</style>
