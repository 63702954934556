const AdminRoutes = [
    {
        path     : '/dashboard',
        name     : 'adminDashboard',
        component: () => import('./pages/Dashboard'),
        meta     : {
            title: 'Admin Dashboard'
        }
    },
    // ===========Role Section===============
    {
        path     : 'role/create',
        name     : 'roleCreate',
        component: () => import('./pages/role/Create'),
        meta     : {
            title: 'Role Create'
        }
    },
    {
        path     : 'role/list',
        name     : 'roleList',
        component: () => import('./pages/role/Index'),
        meta     : {
            title: 'Role List'
        }
    }, {
        path     : 'role/edit/:id',
        name     : 'roleEdit',
        component: () => import('./pages/role/Create'),
        meta     : {
            title: 'Role Edit'
        }
    },
    // ===========Staff Routing===============
    {
        path     : 'staff/list',
        name     : 'staffList',
        component: () => import('./pages/staff/Index'),
        meta     : {
            title: 'Staff List'
        }
    },
    {
        path     : 'staff/create',
        name     : 'staffCreate',
        component: () => import('./pages/staff/Create'),
        meta     : {
            title: 'Staff Create'
        }
    },
    {
        path     : 'staff/edit/:id',
        name     : 'staffEdit',
        component: () => import('./pages/staff/Create'),
        meta     : {
            title: 'Staff Edit'
        }
    },

    // ===========Patient Routing===============
    {
        path     : 'patient/list',
        name     : 'patientList',
        component: () => import('./pages/patient/Index'),
        meta     : {
            title: 'Patient List'
        }
    },
    {
        path     : 'patient/create',
        name     : 'patientCreate',
        component: () => import('./pages/patient/Create'),
        meta     : {
            title: 'Patient Create'
        }
    },
    {
        path     : 'patient/edit/:id',
        name     : 'patientEdit',
        component: () => import('./pages/patient/Create'),
        meta     : {
            title: 'Patient Edit'
        }
    },

    // ===========Prescription Routing===============
    {
        path     : 'prescription/list',
        name     : 'prescriptionList',
        component: () => import('./pages/prescription/Index'),
        meta     : {
            title: 'Prescription List'
        }
    },
    {
        path     : 'prescription/create/:patient_id?',
        name     : 'prescriptionCreate',
        component: () => import('./pages/prescription/Create'),
        meta     : {
            title: 'Prescription Create'
        }
    },
    {
        path     : 'prescription/edit/:id',
        name     : 'prescriptionEdit',
        component: () => import('./pages/prescription/Create'),
        meta     : {
            title: 'Prescription Edit'
        }
    },
    {
        path     : 'prescription/preview/:id',
        name     : 'prescriptionPreview',
        component: () => import('./pages/prescription/Preview'),
        meta     : {
            title: 'Prescription Preview'
        }
    },
    {
        path     : 'prescription/print/:id',
        name     : 'prescriptionPrint',
        component: () => import('./pages/prescription/print/Index'),
        meta     : {
            title: 'Print'
        }
    },

    // ===========Medicine Routing===============
    {
        path     : '/medicines',
        name     : 'medicines',
        component: () => import('./pages/medicine/Index'),
        meta     : {
            title: 'Medicine'
        }
    },
    // ===========Service Routing===============
    {
        path     : '/services',
        name     : 'services',
        component: () => import('./pages/service/Index'),
        meta     : {
            title: 'Services'
        }
    },
    // ===========Testimonial Routing===============
    {
        path     : '/testimonial',
        name     : 'testimonial',
        component: () => import('./pages/testimonial/Index'),
        meta     : {
            title: 'Testimonials'
        }
    },
    // ===========Category Routing===============
    {
        path     : '/categories',
        name     : 'categories',
        component: () => import('./pages/category/Index'),
        meta     : {
            title: 'Categories'
        }
    },
    // ================================
    // Invoice Route
    // ================================
    {
        path     : '/attendance-list',
        name     : 'attendanceList',
        component: () => import('./pages/attendance/Index'),
        meta     : {
            title: 'Attendance List'
        }
    },
    // ================================
    // Invoice Route
    // ================================
    {
        path     : 'invoice/list',
        name     : 'invoiceList',
        component: () => import('./pages/invoice/Index'),
        meta     : {
            title: 'Invoice List'
        }
    },
    {
        path     : 'invoice/create/:patient_id?',
        name     : 'invoiceCreate',
        component: () => import('./pages/invoice/Create'),
        meta     : {
            title: 'Invoice Create'
        }
    },
    {
        path     : 'invoice/view/:id',
        name     : 'invoiceView',
        component: () => import('./pages/invoice/Details'),
        meta     : {
            title: 'Invoice View'
        }
    },
    {
        path     : 'invoice/edit/:id',
        name     : 'invoiceEdit',
        component: () => import('./pages/invoice/Create'),
        meta     : {
            title: 'Invoice Edit'
        }
    },
    // ===========Accounts Routing===============
    {
        path     : '/accounts',
        name     : 'accounts',
        component: () => import('./pages/accounts/Index'),
        meta     : {
            title: 'Accounts'
        }
    },
];

export default AdminRoutes;
