<!--if user not active, then logout after 5 minutes automatically -->
var _imin,
    _isec,
    _MIN      = 0,
    IDLE_TIME = 0;

//_imin = 0; // IDLE time in minutes
_isec = 301; //5 minutes

// On Mouse Move
document.addEventListener('mousemove', function () {
    //  _MIN = 0;
    IDLE_TIME = 0;
});

// On Click
document.addEventListener('click', function () {
    //_MIN = 0;
    IDLE_TIME = 0;
});

// On Key Press
document.addEventListener('keypress', function () {
    //_MIN = 0;
    IDLE_TIME = 0;
});

window.setInterval(getIdleTime, 1000);

function getIdleTime() {
    var _minute,
        _sec,
        _nmin,
        _nsec;

    IDLE_TIME++;
    if (process.env.VUE_APP_ENV
        === 'live'
        && (_isec - IDLE_TIME)
        === 0
        && window.localStorage.getItem('access_token')) {
        //_MIN++;
        //alert('Your Session has expired!!!!');
        window.localStorage.removeItem('access_token');
        document.location.href = '/';
        IDLE_TIME              = 0;
    }

    /*_sec = _isec - IDLE_TIME;

    //_nmin = (_minute.toString().length == 1) ? '0' + _minute : _minute;
    _nsec = (_sec.toString().length === 1) ? '0' + _sec : _sec;

    document.getElementById('SetIdleTime').innerHTML = _nsec + " seconds";*/
}
