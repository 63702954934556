<template>
    <div id="home">
        <Menu/>
        <Slider/>
        <open-hours/>
        <we-specialize/>
        <about-us/>
        <our-service/>
        <!--         <our-department/>-->
        <why-choose-us/>
        <doctors/>
        <testimonial/>
    </div>
</template>

<script>
import Slider              from "../component/home/Slider";
import OpenHours           from "../component/home/OpenHours";
import WeSpecialize        from "../component/home/WeSpecialize";
import AboutUs             from "../component/home/AboutUs";
import OurService          from "../component/home/OurService";
import Testimonial         from "../component/home/Testimonial";
import Doctors             from "../component/home/Doctors";
import OurDepartment       from "../component/home/OurDepartment";
import WhyChooseUs         from "../component/home/WhyChooseUs";
import Menu                from "../layouts/Menu";
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";

export default {
    name      : "Home",
    components: {
        Menu, WhyChooseUs, OurDepartment, Doctors, Testimonial,
        OurService, AboutUs, WeSpecialize, OpenHours, Slider
    },
    data      : () => ({
        doctors     : [],
        services    : [],
        settings    : {},
        testimonials: {},
    }),
    created() {
        this.getHomePageData();
    },
    methods: {
        getHomePageData() {
            ApiService.get(`/home-page-data`).then((res) => {
                this.services     = res.data.services;
                this.doctors      = res.data.doctors;
                this.settings     = res.data.settings;
                this.testimonials = res.data.testimonials;
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        }
    }
}
</script>
