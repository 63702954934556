import Vue                   from 'vue'
import Pagination            from "@/components/Pagination";
import TableAction           from "@/components/TableAction";
//Action Button
import FilterPrintAction     from "@/components/action/FilterPrint";
import IndexAction           from "@/components/action/Index";
import PrintAction           from "@/components/action/Print";
import ViewAction            from "@/components/action/View";
import CreateAction          from "@/components/action/Create";
import UpdateAction          from "@/components/action/Update";
import DeleteAction          from "@/components/action/Delete";
import ButtonAction          from "@/components/action/Button";
//DropDown
import DropDown              from "@/components/dropdown/DropDown";
import DropDownPatientDoctor from "@/components/dropdown/DropDownPatientDoctor";


//
// Vue.component('pagination', Pagination);
Vue.component('table-action', TableAction);
//Action Button Component
Vue.component('filter-print-action', FilterPrintAction);
Vue.component('index-action', IndexAction);
Vue.component('print-action', PrintAction);
Vue.component('view-action', ViewAction);
Vue.component('create-action', CreateAction);
Vue.component('update-action', UpdateAction);
Vue.component('delete-action', DeleteAction);
Vue.component('button-action', ButtonAction);

//DropDown Component
Vue.component('drop-down', DropDown);
Vue.component('drop-down-patient-doctor', DropDownPatientDoctor);
