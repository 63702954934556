<template>
<div id="our-service-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-10 offset-1">
                <div class="common-title-section text-center">
                    <h1>Our Service</h1>
                    <span class="decor"><span class="inner"></span></span>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-4 mb-4" v-for="service in $parent.services">
                        <div class="align-items-center d-flex h-100 justify-content-sm-center justify-content-start mb-3 mb-5 rounded shadow-sm">
                            <div class="flex-shrink-0 icon me-4 rounded-circle">
                                <img class="w-100" src="@/assets/icon/brush-teeth.png" alt="">
                            </div>
                            <div class="service-content">
                                <h3>{{service.name}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "OurService",
    data() {
        return {
        }
    },
}
</script>

<style scoped>

</style>
