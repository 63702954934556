<template>
    <div id="about-section" class="sec-p-70">
        <div class="d-flex flex-wrap align-items-center">
            <div class="image w-50 w-col-100">
                <img class="w-100" src="@/assets/full-image-1.jpg" alt="">
            </div>
            <div class="about-content w-50  w-col-100">
                <div class="common-title-section common-title-section2 ms-5">
                    <h1>More about us</h1>
                    <span class="decor">
                        <span class="inner"></span>
                    </span>
                </div>
                <div class="mx-2 mx-sm-5 skill-section">
                    <h3 class="mb-3 pe-4">We Provide Most Professional Service with Love and Care, Since 2011</h3>
                    <div class="mb-2">
                        <div class="box-title">
                            Scaling
                        </div>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width: 80%;" aria-valuenow="80"
                                 aria-valuemin="0" aria-valuemax="100">80%
                            </div>
                        </div>
                    </div>
                    <div class="mb-2">
                        <div class="box-title">
                            Cap & Bridge
                        </div>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width: 70%;" aria-valuenow="70"
                                 aria-valuemin="0" aria-valuemax="100">70%
                            </div>
                        </div>
                    </div>
                    <div class="mb-2">
                        <div class="box-title">
                            Root Canal
                        </div>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width: 85%;" aria-valuenow="85"
                                 aria-valuemin="0" aria-valuemax="100">85%
                            </div>
                        </div>
                    </div>
                    <div class="mb-2">
                        <div class="box-title">
                            Tooth whitening
                        </div>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width: 75%;" aria-valuenow="75"
                                 aria-valuemin="0" aria-valuemax="100">75%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AboutUs"
}
</script>

<style scoped>

</style>
