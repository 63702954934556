<template>
    <div id="testimonial-section" class="sec-p-70">
        <div class="container-fluid">
            <div class="row">
                <div class="col-10 offset-1">
                    <div class="common-title-section text-center colored ">
                        <h1>Testimonials</h1>
                        <span class="decor"><span class="inner"></span></span>
                    </div>
                    <div class="row" v-if="$parent.testimonials.length">
                        <carousel :responsive="{0:{items:1,nav:false},600:{items:3,nav:false}}" :items="3" :margin="30"
                                  :dots="false" :autoplay="true" :loop="true" :nav="true" :autoplayHoverPause="true"
                                  class="mb-5">
                            <div class="item" v-for="(testimonial , index) in $parent.testimonials">
                                <div class="d-flex align-items-center">
                                    <div class="author-image">
                                        <img :src="testimonial.image" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>{{ testimonial.patient ? testimonial.patient.name : "" }}</h3>
                                        <!-- <div class="designation fs-14 my-2">Patient</div>-->
                                        <div class="d-flex gap-2 mt-1 rating text-warning">
                                            <span v-for="i in testimonial.rating" class="fa fa-star"></span>
                                        </div>
                                    </div>
                                </div>
                                <p class="fs-14 text-secondary mt-4">{{ testimonial.message }} </p>
                            </div>
                        </carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
    components: {carousel},
    name      : "Testimonial"
}
</script>
