<template>
    <div class="nav-section sticky-top shadow-sm">
        <nav class="navbar navbar-expand-lg navbar-dark bg-primary py-1">
            <div class="container-fluid">
                <div class="col-10 offset-1">
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0 text-uppercase">
                            <li class="nav-item">
                                <a class="nav-link text-white active" aria-current="page" href="#home">HOME</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-white" href="#about-section">ABOUT</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-white" href="#our-service-section">Services</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-white" href="#doctors-section">doctors</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-white" href="#footer">contact us</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    name: "Menu"
}
</script>

<style scoped>

</style>
