export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('./Login'),
        meta: {
            title: 'Login'
        }
    },
];
