import Home from "@/views/web/pages/Home";
import About from "@/views/web/pages/About";

const webRoutes = [
    {
        path: '',
        name: 'Home',
        component: () => import('./pages/Home'),
        meta: {
            title: 'Home'
        }
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('./pages/About'),
        meta: {
            title: 'About'
        }
    },
];

export default webRoutes;
