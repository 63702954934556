<template>
    <div id="open-hours">
        <div class="d-flex flex-column flex-sm-row">
            <div class="align-items-center d-flex flex-column flex-item flex-sm-row justify-content-center">
                <div class="icon fs-1 me-sm-3">
                    <div class="fa fa-clock"></div>
                </div>
                <div class="content">
                    <h3 class="text-uppercase text-center text-sm-start">open hours</h3>
                    <div class="d-flex">
                        <div class="fs-12">Saturday-Thursday</div>
                        <div class="fs-12 ms-5 align-self-start">10:30 AM - 2:00 PM &<br> 5:00 PM - 10:30 PM</div>
                    </div>
                    <div class="d-flex">
                        <div class="fs-12 w-50">Friday</div>
                        <div class="fs-12 ms-4 align-self-start">Closed</div>
                    </div>
                </div>
            </div>
            <div class="align-items-center d-flex flex-column flex-item flex-sm-row justify-content-center">
                <div class="icon fs-1 me-sm-3">
                    <div class="fa fa-calendar"></div>
                </div>
                <div class="content text-center">
                    <h3 class="text-uppercase">appointment</h3>
                    <div class="d-sm-flex justify-content-between">
                        <p class="fs-12">(+880) 1720-425485</p>
                    </div>
                </div>
            </div>
            <div class="align-items-center d-flex flex-column flex-item flex-sm-row justify-content-center">
            <div class="icon fs-1 me-sm-3">
                <div class="fa fa-dollar"></div>
            </div>
            <div class="content text-center">
                <h3 class="text-uppercase">online bill system</h3>
                <div class="d-sm-flex justify-content-between">
                    <p class="fs-12">Bkash & Nagad available</p>
                </div>

            </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OpenHours"
}
</script>

<style scoped>

</style>
